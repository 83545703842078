<template>
  <b-modal
    id="typography-settings-modal"
    centered
    no-close-on-backdrop
    footer-class="flex-nowrap"
  >
    <template #modal-header>
      <h3 v-if="tag === 'p'">EDIT PARAGRAPH P</h3>
      <h3 v-else>EDIT HEADLINE {{ tag }}</h3>
    </template>
    <div class="px-4 py-2">
      <div class="preview-box p-4 mb-4" :style="styleObject">
        The quick brown fox jumps over the lazy dog
      </div>
      <div>
        <b-row class="mb-4">
          <b-col cols="12" sm="3">
            <label class="mb-0 mt-2">
              Font Family
            </label>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-select
              v-model="fontFamily"
              :options="fontList"
              @change="fontFamilyHasChanged"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-tabs content-class="mt-3">
          <b-tab title="Desktop" active @click="preview('screen')">
            <modal-content-font-settings
              v-if="localTypographyStyles"
              device-type="screen"
              :styles="localTypographyStyles.screen"
              @data-has-changed="dataHasChanged($event, 'screen')"
            />
          </b-tab>
          <b-tab title="Tablet" @click="preview('tablet')" lazy>
            <modal-content-font-settings
              v-if="localTypographyStyles"
              device-type="tablet"
              :styles="localTypographyStyles.tablet"
              @data-has-changed="dataHasChanged($event,'tablet')"
            />
          </b-tab>
          <b-tab title="Smartphone" @click="preview('mobile')" lazy>
            <modal-content-font-settings
              v-if="localTypographyStyles"
              device-type="mobile"
              :styles="localTypographyStyles.mobile"
              @data-has-changed="dataHasChanged($event,'mobile')"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <b-button
        class="p-3 border-right-light-grey"
        block
        @click="closeTypographySettingsModal(false)"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="p-3"
        block
        @click="closeTypographySettingsModal"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalTypographySettings',
  components: {
    ModalContentFontSettings: () => import('@/components/modals/modalContent/ModalContentFontSettings.vue'),
  },
  mounted() {
    this.$bvModal.show('typography-settings-modal');
  },
  computed: {
    fontList() {
      const familyArray = [];
      familyArray.push(this.firstFontFamily);
      if (this.secondFontFamily && this.secondFontFamily !== this.firstFontFamily) {
        familyArray.push(this.secondFontFamily);
      }
      if (this.currentSelectedFamily && !familyArray.includes(this.currentSelectedFamily)) {
        familyArray.push(this.currentSelectedFamily);
      }
      familyArray.sort((a, b) => a.localeCompare(b));
      return familyArray;
    },
  },
  props: [
    'tag',
    'firstFontFamily',
    'secondFontFamily',
    'currentSelectedFamily',
    'typographyStyles',
  ],
  data() {
    return {
      fontFamily: 'Verdana',
      styleObject: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'inherit',
      },
      localTypographyStyles: null,
    };
  },
  created() {
    if (this.typographyStyles) {
      this.localTypographyStyles = this.typographyStyles;
      this.fontFamily = this.typographyStyles.fontFamily;
      this.fontFamily = (/^'.*'$/.test(this.fontFamily))
        ? this.fontFamily.substring(1, this.fontFamily.length - 1)
        : this.fontFamily;
      // set the style object for preview initially to the screen device
      this.styleObject = {
        fontFamily: this.fontFamily,
        ...this.typographyStyles.screen,
      };
    } else {
      this.styleObject.fontFamily = this.fontFamily;
    }
  },
  methods: {
    preview(device) {
      // eslint-disable-next-line default-case
      switch (device) {
        case 'screen':
          this.styleObject = {
            fontFamily: this.fontFamily,
            ...this.localTypographyStyles.screen,
          };
          break;
        case 'tablet':
          this.styleObject = {
            fontFamily: this.fontFamily,
            ...this.localTypographyStyles.tablet,
          };
          break;
        case 'mobile':
          this.styleObject = {
            fontFamily: this.fontFamily,
            ...this.localTypographyStyles.mobile,
          };
          break;
      }
    },
    closeTypographySettingsModal(okButtonPressed = true) {
      if (okButtonPressed) {
        this.localTypographyStyles.fontFamily = (this.fontFamily) ? `${this.fontFamily}` : null;
        this.$emit('update-styles', this.localTypographyStyles, this.tag);
      } else {
        this.$emit('update-styles', false);
      }
      this.$bvModal.hide('typography-settings-modal');
    },
    fontFamilyHasChanged(value) {
      this.localTypographyStyles.fontFamily = value;
      this.styleObject.fontFamily = value;
    },
    dataHasChanged(changedData, device) {
      // update the preview
      const changedStyleData = {
        fontSize: changedData.data.fontSize,
        lineHeight: changedData.data.lineHeight,
        fontWeight: changedData.data.fontWeight,
        letterSpacing: changedData.data.spacing,
      };
      this.styleObject = {
        fontFamily: this.fontFamily,
        ...changedStyleData,
      };
      this.localTypographyStyles[device] = {
        ...changedStyleData,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.preview-box {
  background-color: #faf9fa;
  max-height: 60vh;
  overflow: scroll;
}
/deep/ .nav-tabs {
  border-bottom: 1px solid #D0D4D8;
  .nav-link {
    padding: 8px 16px;
    &.active {
      background: transparent;
      border-left: 1px solid #D0D4D8;
      border-top: 1px solid #D0D4D8;
      border-right: 1px solid #D0D4D8;
      border-bottom: 1px solid #fff;
      color: #53DC86;
    }
  }
}
</style>
