import { render, staticRenderFns } from "./ModalTypographySettings.vue?vue&type=template&id=ece78126&scoped=true"
import script from "./ModalTypographySettings.vue?vue&type=script&lang=js"
export * from "./ModalTypographySettings.vue?vue&type=script&lang=js"
import style0 from "./ModalTypographySettings.vue?vue&type=style&index=0&id=ece78126&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece78126",
  null
  
)

export default component.exports